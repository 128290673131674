var preloadSupport;
(function (w) {
  try {
    preloadSupport = w
      .document
      .createElement("link")
      .relList
      .supports("preload");
  } catch (e) {
    preloadSupport = false;
  }
  return function () {
    return preloadSupport;
  };
}(typeof global !== "undefined"
  ? global
  : this));

function loadJS(u, a) {
  var r = document.getElementsByTagName("script")[0],
    s = document.createElement("script");
  s.src = u;
  a
    ? s.setAttribute("async", a)
    : null;
  r
    .parentNode
    .insertBefore(s, r);
}
if (!window.HTMLPictureElement) {
  loadJS(base_Path + "/js/respimg.min.js", "async");
  loadJS(base_Path + "/js/picturefill.min.js", "async");
}
function loadLink(u, pre) {
  var s = document.getElementsByTagName('link')[0],
    link = document.createElement("link");
  if (pre === "preload") {
    link.rel = pre;
    link.as = "font";
    link.href = u;
    link.type = "font/woff2";
    link.setAttribute("crossorigin", "anonymous");
    link.media= "all";
  }
  s.parentNode.insertBefore(link, s);
}

if (preloadSupport) {
  loadLink((base_Path + "/fonts/fontawesome-webfont.woff2"), "preload");
  loadLink((base_Path + "/fonts/lato-v15-latin-regular.woff2"), "preload");
  loadLink((base_Path + "/fonts/lato-v15-latin-300.woff2"), "preload");
  loadLink((base_Path + "/fonts/lato-v15-latin-italic.woff2"), "preload");
}
